<template>
  <div class="row d-flex flex-row-fluid">
    <!--begin::Aside-->
    <div id="kt_profile_aside" class="flex-row-fluid col-lg-3 mb-8">
      <!--begin::Profile Card-->
      <div class="card card-custom card-stretch">
        <!--begin::Body-->
        <div class="card-body pt-10">
          <!--begin::UserCard-->
          <UserCard />
          <hr />
          <!--end::UserCard-->
          <!--begin::Nav-->
          <Sidebar :items="itemsSidebar" />
          <!--end::Nav-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Profile Card-->
    </div>
    <!--end::Aside-->
    <!--begin::Content-->
    <!-- <transition name="fade-in-up"> -->
    <router-view />
    <!-- </transition> -->
    <!--end::Content-->
  </div>
</template>

<script>
import Sidebar from '@/shared/layout/aside/Sidebar.vue';
import { mapGetters } from 'vuex';
import UserCard from '@/shared/components/user/UserCard';

export default {
  components: {
    Sidebar,
    UserCard
  },
  data() {
    return {
      itemsSidebar: [
        {
          label: 'Minhas Propostas',
          icon: '/media/svg/icons/Communication/Outgoing-box.svg',
          href: '/emission/proposals',
          parents: '/emission/proposals'
        },
        {
          label: 'Meus Imóveis',
          icon: '/media/svg/icons/Home/Building.svg',
          href: '/emission/properties',
          parents: '/emission/properties'
        },
        {
          label: 'Meus Contratos',
          icon: '/media/svg/icons/Communication/Clipboard-check.svg',
          href: '/emission/contracts',
          parents: '/emission/contracts'
        }
      ]
    };
  },
  computed: mapGetters(['currentUser', 'currentStatus', 'currentCustomer', 'currentAddress']),
  mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Emissão de SnailCoin', route: 'emission' }]);
    this.$store.dispatch('GET_PROPOSAL_PARAMS');
  },
  methods: {}
};
</script>

<style></style>
